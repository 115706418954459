/* @refresh reload */
import { render } from "solid-js/web";
import { lazy } from "solid-js";
import { Route, Router } from "@solidjs/router";

import "./index.css";

const Splash = lazy(() => import("./pages/Splash"));
const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/Home"));
const Join = lazy(() => import("./pages/Join"));

const root = document.getElementById("root");
if (root) {
  render(
    () => (
      <Router>
        <Route path="/" component={Splash} />
        <Route path="/login" component={Login} />
        <Route path="/home" component={Home} />
        <Route path="/join" component={Join} />
      </Router>
    ),
    root,
  );
}
